import "./multipleChoiceActivity.css"
import React from 'react';
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Fullscreen } from "components/shared/Fullscreen/Fullscreen";
import { useActivity } from "hooks/useActivity/useActivity";
import { useOkoSessionContext } from "hooks/useOkoSession/useOkoSession";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";
import { useLocalParticipantUserId } from "hooks/useLocalParticipant/useLocalParticipant.js";
import { VideosBar } from "components/pages/Session/shared/VideosBar/VideosBar";
import useLocalVideoToggle from "hooks/livekit/useLocalVideoToggle/useLocalVideoToggle";
import { MultipleChoiceActivityRender } from "./MultipleChoiceActivityRender";

export const MultipleChoiceActivity = () => {
  const {
    consensusSession: { consensusResponse },
  } = useOkoSessionContext();
  const { activity } = useActivity();
  const [timerValue, setTimerValue] = useState(null);
  const [timerTimeRemaining, setTimerTimeRemaining] = useState(null);
  const localParticipantUserId = useLocalParticipantUserId();
  const { update: updateActivity } = useActivity();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [disableOptions, setDisableOptions] = useState(false);
  const [hideQuestion, setHideQuestion] = useState(true);
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const [isVideoToggled, setIsVideoToggled] = useState(false);

  useDataTrack((e) => {
    if (e.event_type !== "time_warning") return;
    if (!e?.data?.oko_user_ids?.includes(localParticipantUserId)) return;
    setTimerValue(e.data.time_remaining_seconds);
  });

  useDataTrack((e) => {
    if (timerValue && e.event_type === "speech_end") {
      setTimerTimeRemaining(timerValue);
    }
  });

  useDataTrack((e) => {
    if (e.event_type !== "state_change") return;

    const data = { ...e?.data?.context, event_id: e.event_id };
    updateActivity(data, "multiple-choice");

    if (e?.data?.state === "challenge") {
      setHideQuestion(true); // Unmounting is required for correct MathJax rendering between challenges.
      setHideQuestion(false);
      setSelectedIndex(null);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setDisableOptions(true);
    }

    if (e?.data?.state === "gather") {
      setHideQuestion(false);
      setSelectedIndex(null);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setDisableOptions(false);
    }

    if (e?.data?.state === "discuss") {
      setHideQuestion(false);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setSelectedIndex(null);
      setDisableOptions(true);
    }

    if (e?.data?.state === "solution") {
      setSelectedIndex(null);
      setDisableOptions(true);
      setTimerTimeRemaining(null);
      setTimerValue(null);
      setHideQuestion(false);
    }
  });

  const onAnswer = async (option, index) => {
    if (disableOptions) return;
    if (!option.eligible) return;
    if (selectedIndex !== null) return;
    consensusResponse(activity, index);
    setSelectedIndex(index);
    setDisableOptions(true);
    setTimerTimeRemaining(null);
  };

  useEffect(() => {
    if (!isVideoToggled && isVideoEnabled) {
      toggleVideoEnabled();
      setIsVideoToggled(true);
    }
  }, []);

  useEffect(()=> {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset()
    }
  },[activity]);

  if (!activity) return null;
  const { challenge } = activity;

  return (
    <StyledFullscreen>
      <MultipleChoiceActivityRender
        hideQuestion={hideQuestion}
        timerTimeRemaining={timerTimeRemaining}
        selectedIndex={selectedIndex}
        challenge={challenge}
        disableOptions={disableOptions}
        onAnswer={onAnswer}
        isMathJaxSet={true}
      />
    <div style={{display: "none"}}>
      <VideosBar />
    </div>
  </StyledFullscreen>
  );
};

export const StyledFullscreen = styled(Fullscreen)`
  display: grid;
  align-items: center;
  justify-items: center;
  max-height: calc(100vh - 80px);
  min-height: 100%;
`;
