import { useCollectionData } from "react-firebase-hooks/firestore";
import { useFirebase } from "hooks/useFirebase";
import { useAuthState } from "react-firebase-hooks/auth";

export const useFirebaseRef = () => {
  const firebase = useFirebase();
  return firebase
    .firestore()
    .collection("settings/admin/AuthorizedMeetingUsers");
};

export const useAuthorizedMeetingUserByEmail = () => {
  const firebase = useFirebase();
  const [authUser] = useAuthState(firebase.auth());

  const ref = useFirebaseRef();
  let [users, loading, error] = useCollectionData(
    ref.where("email", "==", authUser.email).limit(1),
    {
      idField: "id",
    }
  );

  return { user: users?.[0], loading, error, authUser };
};
