import styled from "styled-components";

import { Activity } from "./Activity/Activity";
import { VideosBar } from "components/pages/Session/shared/VideosBar/VideosBar";
import { CalculatorActivityProvider } from "hooks/activities/calculator/useCalculatorActivity/useCalculatorActivity";

import { VideoUserStatus } from "./VideoUserStatus/VideoUserStatus";
import { useSessionContext } from "hooks/useSession";

export const CalculatorActivity = () => {
  const { userIsSessionAdmin } = useSessionContext();

  return (
    <CalculatorActivityProvider>
      <Container>
        <Activity />
        <VideosBar videoWidget={userIsSessionAdmin && VideoUserStatus} />
      </Container>
    </CalculatorActivityProvider>
  );
};

const Container = styled.div`
  padding-top: 80px;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;
  top: 80px;
`;
