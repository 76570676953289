import "./side.css";
import { EducatorSide } from "./pages/Educator/EducatorSide";
import { colors } from "utils";
import { Header } from "./Header";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdminSide } from "./pages/Admin/AdminSide";

export const SidebarView = ({ sideVariant, viewComponent, headerTitle }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const openSidebar = () => {
    document.getElementById("sidebar-toggle").style.display = "flex";
    document.getElementById("main-view").style.marginLeft = "312px";
  }

  const closeSidebar = () => {
    document.getElementById("sidebar-toggle").style.display = "none";
    document.getElementById("main-view").style.marginLeft = 0;
  }

  const toggleSidebar = () => {
    if (isSidebarOpen) {
      closeSidebar();
    } else {
      openSidebar();
    }
    setIsSidebarOpen(!isSidebarOpen);
  };

  let sideComponent;

  if (sideVariant === "educator") {
    sideComponent = <EducatorSide showCloseButton={isSidebarOpen} toggleSidebar={toggleSidebar} />;
  } else if (sideVariant === "admin") {
    sideComponent = <AdminSide showCloseButton={isSidebarOpen} toggleSidebar={toggleSidebar} />;
  }

  return (
    <div>
      {sideComponent}
      <div id="main-view" style={{ marginLeft: "312px", transition: "margin-left .05s" }}>
        <Header title={headerTitle}/>
        {viewComponent}
      </div>
      {!isSidebarOpen &&
        <Button id="sidebar-toggle"
                onClick={toggleSidebar}
                style={{
                  backgroundColor: colors.nightlyWoods500,
                  border: "none",
                  position: "absolute",
                  left: "8px",
                  top: "28px" }}>
          <FontAwesomeIcon icon={faBars} />
        </Button>}
    </div>
  );
};
