import "./gettingStartedModal.css";
import { Button, Modal } from "react-bootstrap";
import { colors } from "utils";
import Yay from "assets/yay.png";
import { useAuthorizedMeetingUserByEmail } from "hooks/useAuthorizedMeetingUsers/useAuthorizedMeetingUsers";

export const EducatorGettingStartedModal = ({ isOpen, setIsOpen }) => {
  const { authUser } = useAuthorizedMeetingUserByEmail();

  const handleClose = () => {
    window.localStorage.setItem("isDismissEducatorGettingStartedModal", "true");
    setIsOpen(false);
  };

  return (
    <Modal className="getting-started-modal" show={isOpen}>
      <Modal.Header style={{
        border: "none",
        padding: 0 }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img src={Yay} style={{ alignSelf: "center", maxWidth: "100%" }} />
          <strong style={{ fontSize: "36px", textAlign: "center" }}>Welcome to OKO Labs!</strong>
        </div>
      </Modal.Header>
      <div style={{
        alignItems: "center",
        display: "flex",
        height: "16px",
        justifyContent: "center" }}>
        <div style={{ border: "0.5px solid #D0D5DD", height: 0, width: "100%" }}>
        </div>
      </div>
      <div style={{ fontSize: "18px" }}>
        <p className="getting-started-item">Hello, {authUser.displayName}!</p>
        <p className="getting-started-item">Next Steps:</p>
        <p className="getting-started-item">&#8226;&nbsp;For each group code on your dashboard, select a grade level
          for the Let’s Talk activity’s fractions content. Any session started using this code will use questions
          based on the selected grade. You can select a different grade level for each class code to accommodate
          the differing levels of fractions mastery in your classroom.</p>
        <p className="getting-started-item">&#8226;&nbsp;Write your group codes where students can see them.</p>
        <p className="getting-started-item">&#8226;&nbsp;Select a small group of 2 – 5 students, and sit them near
          each other, each with a laptop and headphones. (We recommend using the Chrome browser.)</p>
        <p className="getting-started-item">&#8226;&nbsp;Tell students to visit&nbsp;
          <a href={process.env.REACT_APP_OKOLABS_APP_URL}>{process.env.REACT_APP_OKOLABS_APP_URL}</a>
          &nbsp;and enter the same group code.</p>
        <p className="getting-started-item">At <a href={process.env.REACT_APP_OKOLABS_APP_URL}>
          {process.env.REACT_APP_OKOLABS_APP_URL}</a>, students can enter any of your group codes.</p>
      </div>
      <div style={{
        alignItems: "center",
        display: "flex",
        marginBottom: "16px",
        height: "16px",
        justifyContent: "center" }}>
        <div style={{ border: "0.5px solid #D0D5DD", height: 0, width: "100%" }}>
        </div>
      </div>
      <div style={{ display: "flex", gap: "24px", justifyContent: "space-between" }}>
        <Button onClick={handleClose} style={{
          backgroundColor: colors.nightlyWoods500,
          border: "none",
          padding: "12px 24px",
          width: "100%" }} >
          Dismiss
        </Button>
      </div>
    </Modal>
  );
};
